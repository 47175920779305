    *{
    box-sizing: border-box;
    font-family: Verdana, sans-serif;

    }
    body{
    background-color: white;
    margin: 0;
    display: flex;
    overflow-x: hidden;
    padding: 0;
    width: 100vw;
    height: 100vh;
    font-family: 'Inter',sans-serif;
    background-color: rgb(223, 234, 245);
    padding-top: 8vh;
    }

    /*head bar*/
    .head-bar{
        background-image: linear-gradient(to left,#3cbaec,#1f99c9);
        height: 9vh;
        display: flex;
        width: 100vw;
        padding: 0 20px 0 20px ;
    }
    .logo-img{
        margin: auto 0; 
        width: 6vw;
        min-width:60px;
    }
    .head-list,.head-list-open{
        list-style: none;
        display: flex;
        margin: auto 0 auto auto;
        gap: 2vw;
        font-size:2.2vmin;
    }
    .right-head{
        display: flex;
        gap: 1.5vw;
        margin-left: 3vw;
    }
    .cart-area{
        display: flex;
        margin: auto;
    }
    .cart-word{
        margin: auto;
        
    }
    .cart-img{
    width: 3vmin;
    padding-left:2px ;
    margin: auto;
    }
    .sign-btn{
        padding:1vmin 2vw;
        background-color: #158ebe;
        border-radius: 5px;
        border: none;
        color: rgb(223, 232, 243);
        font-size:2vmin;
        margin-right: 10px;
    }
    .sign-btn:hover{
        cursor:pointer;
        background-color: #2fa3d1;
    }
    li{
        margin: auto;
        float: left;
        color: rgb(223, 232, 243);
        white-space: nowrap; /*to provide break line*/
        cursor: pointer;
    }
    a{
        color: black;
        text-decoration: none;
    }
    .link-color{
    color: rgb(223, 232, 243);
    display: flex;
    margin: auto;
    }
    .line{
        display: none;
        
    }
    .list-icon{
        font-size:1.5rem;
        color: rgb(223, 232, 243);
        opacity: .7;
        display: none;
    }
    .list-btn{
        background-color: transparent;
        border: none;
        margin-left: auto;
        cursor: pointer;
        display: none;
    }
    .head-bar{
        position: fixed;
        top: 0;
        z-index: 1;
        left: 0;
    }
    @media screen and (max-width:700px) {

        .list-icon{
            display: block;
        }
        .list-btn{
            display: block;
        }
        .head-list{
            display: none;
        }
        .head-list-open{
            font-size:1.1rem;
            display: flex;
            flex-direction: column;
            background-color: rgb(40,164,212);
            position: fixed;
            right: 0;
            z-index: 1;
            height:calc(100% - 9vh) ;
            margin: 0 auto;
            text-align: center;
            padding: 0;
            width: 50%;
            top: 9vh;
            list-style: none;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            animation: openList 0.5s;
        }
        .right-head{
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            width: 100%;
            padding: 10px 0;
        }
        .cart-area{
            margin:10px auto;
        }
        .cart-word{
            margin:0 0 0 auto;
        }
        .cart-img{
            margin: 0 auto 0 0;
            width: 20px;
        }
        .sign-btn{
            margin: 10px auto;
            font-size: 1.2rem;
            padding: .5rem 2rem;
            background-color: #0cc4d1;
        }
        li{
            margin: 0 auto;
            width: 100%;
            padding: 10px 0;
        }
        .line{
            width: 100%;
            display: block;
            border: 0;
            border-top: 2px solid rgba(207, 207, 207, 0.2);
            
        }
    }
    @keyframes openList {
        0% {
            width: 0;
        }
        100% {
            width: 50%;
        }
    }
    /* end of head bar */
    /*cards*/
    .header-img{
        width: 100%;
        height: 70vh;
        overflow: hidden;
        margin: 0;
        padding: 0;
        object-fit: cover;
    }
    /* products */
    .lap-bar{
        height: 7vh;
        width: 100vw;
        padding: 0 20px ;
        background-image: linear-gradient(to left , rgb(179, 211, 243) ,  rgb(83, 160, 216) );
        display: flex;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-top: -4px;
    }
    .lap-top{
        margin: auto 0;
        background: -webkit-linear-gradient(rgb(60, 100, 233), rgb(36, 40, 235));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 3vmin;
    }
    .cont{
    display: flex;
    flex-wrap: nowrap;
    overflow-x:scroll; 
    padding:30px 1vmin 10px 1vmin ;
    width: 95vw;
    margin: auto;
    margin-bottom: 10px;
    }
    .cont::-webkit-scrollbar {
        width: 20px;
    }
    .cont::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }
    .cont::-webkit-scrollbar-thumb {
        background: rgb(120,209,245); 
        border-radius: 10px;
    }
    .cont::-webkit-scrollbar-thumb:hover {
        background: #2a73d3; 
    }
    .lap-cards{
        margin: 20px;
        display: flex;
        background-color: rgb(242,242,242);
        border-radius: 10px;
        width: 30vw;
        max-width: 40vmin;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        flex: 0 0 auto;
        padding-bottom: 25px;
        position: relative;
        padding: 10px;
    }
    .card-prop{
        border-radius: 10px;
        padding:10px ;
    }
    .one-card{
        display: flex;
        flex-direction: column;
        gap: 7px;
        width: 100%;
        position: relative;
    }
    .card-ditailes{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .lap-name{
        margin: 0;
        padding: 0;
        font-size: 2.5vmin;
    }
    .star--info {
        padding: 10px;
    }
    .star--favorite {
        width: 3vw;
        max-width: 20px;
        cursor: pointer;
    }
    .state{
        display: flex;
        justify-content: space-between;
    }
    .heart{
        width: 3vw;
        max-width: 25px;
        margin: auto 0;
        cursor: pointer;
    }
    .card-prop{
        width: 100%;
    }
    .current-price{
        margin: 0 5px;
        font-weight: bolder;
        font-size: 2vmin;

    }
    .old-price{
        margin:0 5px;
        text-decoration: line-through;
        font-weight: bolder;
        padding-bottom: 50px;
        font-size: 2vmin;
    }
    .card-cart{ 
    display: flex;
    padding: 6px;
    align-content: center;
    border-radius: 10px;
    border: 1px solid rgb(5, 76, 168);
    background-color: #4894f8;
    cursor: pointer;
    display: flex;
    width: 100%;
    }
    .add-cart{
    display: flex;
    position: absolute;
    bottom: 5px;
    width: 100%;
}
    
    .card-cart>img{
        width: 2vmin;
        margin: auto;
        margin-left: 0;
    }
    .card-cart>h4{
    margin: auto;
    margin-right: 10px;
    font-size: 2vmin;
    }
    @media screen and (max-width:700px) {
        .lap-cards{
            width: 40%;
        }
        .lap-name{
            font-size: 3vw;
            padding-left: 3px;
        }
        .current-price{
            font-size: 3vmin;
        }
        .old-price{
            font-size: 3vmin;
        }
        .card-cart>img{
            width: 2.5vmin;
        }
        .card-cart>h4{
        font-size: 3vmin;
    }}


    /*Cart*/
    .cart-cards-cont{
        display: flex;
        background-color: #F2F2F2;
        width: 90%;
        border-radius: 20px;
        padding: 15px;
        position: relative;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin: 20px auto;
    }
    .cart-cards-cont-link{
        border-radius: 20px;
        width: 30%;
        margin: auto;
    }
    .cart-cont-img{
        border-radius: 20px;
        width: 100%;
        margin: auto;
        }
    .cart-content-name{
        margin-right: auto;
        margin-top:0 ;
        margin-bottom: 0;
        margin:0 auto 0 auto;
        font-size:3vmin;
        padding-right:10px;
    }
    .cart-ditailes{
        font-size: 2vmin;
        display: flex;
        flex-direction: column;
        width:70% ;
        margin-left: 10px;
    }
    .cart-discription{
        width: 100%;
    }
    .cart-heart{
        height: 3vw;
        max-height: 20px;
        margin-right: 15px;
        cursor: pointer;
        position:absolute;
        right: 0px;
        top: 15px;
    }
    .car-old-price{
        margin:2px 5px;
        text-decoration: line-through;
        font-weight: bolder;
    }
    .cart-current-price{
        margin: 5px 5px 7vw 5px;
        font-weight: bolder;
    }
    .buy-now{
        background-color: rgb(255,164,28);
        border: none;
        font-size: 2vmin;
        border-radius: 50px;
        bottom: 2vw;
        height: 5vmin;
        cursor: pointer;
        margin: auto;
        width: 100%;
    }
    .checkout{
        display: flex;
        gap: 20px;
        width: 100vw;
        margin:30px auto ;
        padding-bottom: 50px;
        justify-content: space-around;
    }
    .checkout-btn{
        width: 45%;
        border-radius: 50px;
        border: 1px solid rgb(201, 181, 0);
        background-color:#F7CA00;
        font-size: 2vmin;
    }
    .checkout-btn:hover{
        cursor: pointer;
        background-color:#fad532 ;
    }
    .clCart-btn{
        grid-column: 1 /-1;
        padding: 15px;
        border-radius: 50px;
        width: 45%;
        border: 1px solid rgb(201, 181, 0);
        background-color:rgb(187, 86, 4);
        font-size: 2vmin;
    }
    .clCart-btn:hover{
        cursor: pointer;
        background-color: rgb(187, 102, 32);
    }

    /* saved product */
    .fav-cont{
        display:flex;
        flex-direction: column;
        width:100vw;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .favurite-card-cart{
        display: flex;
        border: none;
        margin: auto;
        width: 64%;
        font-size: 2vmin;
        border-radius: 50px;
        position: absolute;
        bottom: 2vw;
        height: 5vmin;
        border: 1px solid rgb(5, 76, 168);
        background-color: #4894f8;
        cursor: pointer;
    }
    .favurite-card-cart>img{
        width: 2.5vmin;
        margin: auto;
        margin-left: 0;
    }
    .favurite-card-cart>h4{
        
        margin: auto;
        margin-right: 10px;
    }
    .clear-fav-btn{
        display: block;
        width: 90vw;
        height: 50px;
        margin:30px auto;
        border-radius: 50px;
        border: 1px solid rgb(201, 181, 0);
        background-color:rgb(187, 86, 4);
        font-size: 2vmin;
        cursor: pointer;
    }
    @media screen and (max-width:700px) {
        .clear-fav-btn{
        width: 92vw;
        margin:30px 20px;
    }
}
/*sign in*/
.sign-in-cont{
    width: 100vw;
}
.sign-in-card{
    width: 50%;
    height: 50vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10vh auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    align-items: center;
}
.email-container{
width: 60%;
}
.sign-in-email{
width: 100%;
font-size: 1rem;
margin: 0;
padding :.5rem 0;
}
.sign-in-email-input{
width: 100%;
}
.password-container{
width: 60%;
}
.sign-in-password{
width: 100%;
font-size: 1rem;
margin: 0;
padding :.5rem 0;
}
.sign-in-password-input{
width: 100%;
}
.sign-in-card-in-btn{
    background-color: rgb(39, 108, 236);
    width: 40%;
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.sign-in-card-up-btn{
    width: 40%;
}
.sign-in-card-up-btn>button{
    width: 100%;
    background-color: rgb(48, 196, 97);
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}
@media screen and (max-width:700px) {
    .sign-in-card{
        width: 90%;
        margin:15vh auto ; 
        height: 40vh;
    }
}

/*end of sign in*/

/*sign up*/
.sign-up-cont{
    width: 100vw;
}
.sign-up-card{
    width: 50%;
    height: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin:40px auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}
.frist-name-container{
    width: 60%;
}
.sign-up-frist-name{
    width: 100%;
    font-size: 1rem;
    margin: 0;
    padding-bottom:.5rem;
}
.sign-up-frist-name-input{
    width: 100%;
}
.second-name-container{
    width: 60%;
}
.sign-up-second-name{
    width: 100%;
    font-size: 1rem;
    margin: 0;
    padding :.5rem 0;
}
.sign-up-second-name-input{
    width: 100%;
}
.sign-up-email{
    width: 100%;
    font-size: 1rem;
    margin: 0;
    padding :.5rem 0;

}
.sign-up-email-input{
    width: 100%;

}
.sign-up-password{
    width: 100%;
    font-size: 1rem;
    margin: 0;
    padding :.5rem 0;
}
.sign-up-password-input{
    width: 100%;

}
.sign-up-card-in-link{
    width: 100%;
    color: rgb(0, 89, 255);

}
.sign-up-card-up-btn{
    width: 40%;
    background-color: rgb(48, 196, 97);
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}


@media screen and (max-width:700px) {
    .sign-up-card{
        width: 90%;
        height: 70%;
    }
}
/*end of sign up*/
/*offers*/
.offers-cont{
    width: 100vw;
}
/*end of offers*/
/*card details*/
.card-dit-container{
padding: 30px;
display: flex;
height: 92vh;
}
.card-dit-img-container{
    display: flex;
    margin: auto;
}
.card-dit-img-small{
display: flex;
flex-direction: column;
overflow: hidden;
margin: auto 20px auto 0;

}
.card-dit-img{
width: 130px;
height: 100px;
object-fit: cover;
margin-top: 10px;
cursor: pointer;
}
.card-dit-img-large-cont{
    width: 500px;
    height: 80vh;
    margin: auto 0;
    display: flex;
}
.card-dit-img-large{
    max-width: 400px;
    max-height: 60vh;
    margin: auto;
}
.card-dit-information-cont{
    width: calc(100vw -730px);
    display: flex;
    flex-direction: column;
    margin: auto;
}
.card-dit-state{
    display: flex;
    flex-direction: row;
}
.card-dit-heart{
    width: 30px;
    height: 20px;
    cursor: pointer;
    margin: auto 0 auto auto;
    padding-right:10px;
}
.card-dit-add-to-cart{
    background-color: rgb(39, 108, 236);
    width: 80%;
    border: none;
    margin: auto;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: #F2F2F2;
    font-size: 1rem;
}
@media screen and (max-width:700px) {
    .card-dit-container{
        flex-direction: column;
        padding: 0;
        margin: 0;
        }
        .card-dit-img-container{
            flex-direction: column;
        }
        .card-dit-img-small{
            flex-direction: row;
        gap: 2vw;
        margin: auto;
        }
        .card-dit-img{
            width: 20vw;
            height: 20vw;
            object-fit: cover;
            margin: auto;
            margin-top: 10px;
            cursor: pointer;
            object-fit:fill;
        }
        .card-dit-img-large-cont{
            width: 100%;
            height: auto;
            margin: auto;
            padding: 40px 0;
        }
        .card-dit-img-large{
            margin: auto;
        }
        .card-dit-information-cont{
            width: 85%;
        }
        .card-dit-add-to-cart{
            margin-bottom: 30PX;
        }
}
/*end of card ditailes*/